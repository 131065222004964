
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import SaLinkOrHref from '~/components/_general/SaLinkOrHref.vue'
import SaPicture from '~/components/_general/SaPicture.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'

export default Vue.extend({
  name: 'SiteResizablePromo',
  components: { InlineIcon, SaPicture, SaLinkOrHref },
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String as PropType<TranslateResult>,
      required: true,
    },
    subtitle: {
      type: String as PropType<TranslateResult>,
      default(): TranslateResult {
        return this.$t('A Ministry of SermonAudio')
      },
    },
    description: {
      type: String as PropType<TranslateResult>,
      required: true,
    },
    moreHref: {
      type: String,
      default: '',
    },
    moreTo: {
      type: String,
      default: '',
    },
  },
})
